module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dr. Viktor Dück","short_name":"Dr. Viktor Dück","description":"Informationen, wenn es darauf ankommt","start_url":"/","theme_color_in_head":false,"icon":"src/images/icon.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"04134a5ef8bed74eaa06d8b133d7149e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"quality":80,"backgroundColor":"transparent","linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"loading":"lazy","wrapperStyle":"margin-bottom: 1.0725rem","showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://viktordueck.matomo.cloud","siteUrl":"https://www.viktordueck.de","requireConsent":false,"disableCookies":true,"dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
