exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-artikel-jsx": () => import("./../../../src/pages/artikel.jsx" /* webpackChunkName: "component---src-pages-artikel-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-personaldienstleister-double-optin-erfolgreich-jsx": () => import("./../../../src/pages/personaldienstleister-double-optin-erfolgreich.jsx" /* webpackChunkName: "component---src-pages-personaldienstleister-double-optin-erfolgreich-jsx" */),
  "component---src-pages-personaldienstleister-jsx": () => import("./../../../src/pages/personaldienstleister.jsx" /* webpackChunkName: "component---src-pages-personaldienstleister-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

